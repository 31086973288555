export default {
	data: {
		selectedImageIndex: undefined,
		openstreetmap: false,
		landloardlang: 'greece',
		newsletteremail: ''
	},
	beforeMount: function () {

		this.checkOsmConsent();

		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
		var hash = window.location.hash;
		if (hash.indexOf('email') > 0 && hash.indexOf('=') > 0) {
			this.newsletteremail = hash.substring(hash.indexOf('=') + 1);
		}
	},
	mounted: function () {
		window.addEventListener('ccm19WidgetClosed', (event) => {
			this.checkOsmConsent();
		});
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['website', 'v-office.com']).join('@');
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		}
	},
	methods: {
		getNewsletterUrl: function () {
			return './newsletter#?email=' + this.newsletteremail;
		},
		checkOsmConsent: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			const allowOpenstreet = this.getCookie(cookieName);
			const data = CCM.acceptedEmbeddings;
			//console.log('CCM.acceptedEmbeddings', data);
			if (allowOpenstreet || data.find(item => item.name === 'Google Maps')) {
				this.openstreetmap = true;
			}
		},
		getCookie: function (name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},


		setCookie: function (name, value, days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},


		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName, true, VOFFICE.settings.osmCookieDays);
			this.openstreetmap = true;
		},

		unitImages: function (string) {
			//console.log(string);
		}
	},

};